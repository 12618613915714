import { graphql, StaticQuery } from 'gatsby'
import { Motion, spring } from 'react-motion'
import React, { useRef, useState } from 'react'

import ScrollDrawer from './ScrollDrawer'
import styles from './Header.module.css'

const Header = ({ hidden, location }) => {
  const ref = useRef()
  const buttonRef = useRef()
  const [animating, setAnimating] = useState(false)
  const [buttonEnabled, setButtonEnabled] = useState(true)
  const [scrollWidth, setScrollWidth] = useState(0)

  const onContact = ({ target }) => {
    if (target !== buttonRef.current) {
      setAnimating(false)
    }
  }

  const onButtonClick = () => {
    setScrollWidth(ref.current.offsetWidth)
    setAnimating(true)
  }

  return (
    <StaticQuery
      query={graphql`
        {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (
        <header className={styles.Header}>
          <ScrollDrawer hidden={hidden}>
            <div className={styles.Header_Background}>
              {/* eslint-disable jsx-a11y/no-static-element-interactions */}
              <div
                className={styles.Header_Scroller}
                onScroll={() => setButtonEnabled(ref.current.scrollLeft === 0)}
                onTouchStart={onContact}
                onMouseDown={onContact}
                onWheel={() => setAnimating(false)}
                ref={ref}
              >
                {animating && (
                  <Motion
                    defaultStyle={{ scroll: 0 }}
                    style={{ scroll: spring(scrollWidth) }}
                    onRest={() => setAnimating(false)}
                  >
                    {styles => {
                      ref.current.scrollLeft = styles.scroll
                      return null
                    }}
                  </Motion>
                )}
                <h1>
                  <a href="/">{data.site.siteMetadata.title}</a>
                </h1>
                <button
                  disabled={!buttonEnabled}
                  onClick={onButtonClick}
                  ref={buttonRef}
                  aria-label="Menu"
                />
                <nav>
                  <a
                    href="/"
                    className={
                      location && location.pathname === '/'
                        ? styles.isActive
                        : ''
                    }
                  >
                    Projects
                  </a>
                  <a
                    href="/publications/"
                    className={
                      location && location.pathname.startsWith('/publications/')
                        ? styles.isActive
                        : ''
                    }
                  >
                    Publications
                  </a>
                  <a
                    href="/news/"
                    className={
                      location && location.pathname.startsWith('/news/')
                        ? styles.isActive
                        : ''
                    }
                  >
                    News
                  </a>
                  <a
                    href="/about/"
                    className={
                      location && location.pathname.startsWith('/about/')
                        ? styles.isActive
                        : ''
                    }
                  >
                    About
                  </a>
                </nav>
              </div>
              {/* eslint-enable jsx-a11y/no-static-element-interactions */}
            </div>
          </ScrollDrawer>
        </header>
      )}
    />
  )
}

export default Header
