import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import './Layout.css'
import Footer from './Footer'
import Header from './Header'
import { HeaderVisibilityContext } from './HeaderVisibilityContext'

const Layout = ({ location, children }) => {
  const [headerVisible, setHeaderVisible] = useState(true)

  useEffect(() => {
    const id = window.location.hash.slice(1)
    const el = document.getElementById(id)
    el && el.scrollIntoView(true)
  }, [])

  return (
    <StaticQuery
      query={graphql`
        {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={(data) => (
        <>
          <Helmet
            titleTemplate={`%s | ${data.site.siteMetadata.title}`}
            defaultTitle={data.site.siteMetadata.title}
            meta={[
              {
                name: 'description',
                content:
                  'Official website of WALTZ. The company is a multi-disciplinary firm specializing Graphic and Space design based in Tokyo.',
              },
            ]}
          >
            <html id="__top" lang="ja" />
            <link
              rel="stylesheet"
              href="https://fonts.googleapis.com/css?family=Work+Sans:500"
            />
            <script
              src="//typesquare.com/3/tsst/script/ja/typesquare.js?5BKoKQWh53M%3D"
              async
            />
          </Helmet>
          <HeaderVisibilityContext.Provider
            value={{ headerVisible, setHeaderVisible }}
          >
            <Header hidden={!headerVisible} location={location} />
            <main>{children}</main>
            <Footer />
          </HeaderVisibilityContext.Provider>
        </>
      )}
    />
  )
}
Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
