import React, { useEffect, useState, useRef } from 'react'

import styles from './ScrollDrawer.module.css'

const ScrollDrawer = ({ hidden, children }) => {
  const ref = useRef()
  const [y, setY] = useState(0)

  useEffect(() => {
    let prevScrollY = window.pageYOffset

    const onScroll = () => {
      const currScrollY = window.pageYOffset
      if (currScrollY <= 0) {
        setY(0)
        return
      }
      const elHeight = ref.current.offsetHeight
      const offsetY = prevScrollY - currScrollY
      prevScrollY = currScrollY
      setY(y => Math.max(Math.min(y + offsetY, 0), elHeight * -1))
    }

    window.addEventListener('scroll', onScroll)

    return () => window.removeEventListener('scroll', onScroll)
  }, [])

  return (
    <div
      className={styles.ScrollDrawer}
      style={
        hidden
          ? { transform: `translateY(-100%)` }
          : { transform: `translateY(${y}px)` }
      }
      ref={ref}
    >
      {children}
    </div>
  )
}

export default ScrollDrawer
