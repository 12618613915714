import React from 'react'
import { StaticQuery, graphql } from 'gatsby'

import styles from './Footer.module.css'

const Footer = () => (
  <StaticQuery
    query={graphql`
      {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <footer className={styles.Footer}>
        <a href="/">{data.site.siteMetadata.title}</a>
      </footer>
    )}
  />
)

export default Footer
